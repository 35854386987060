@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 300;
  src: local("Lato Light Italic"), local("Lato-LightItalic"),
    url(../fonts/Lato/Lato-Light-Italic.ttf) format("truetype"),
    url(../fonts/Lato/Lato-Light-Italic.woff2) format("woff2"),
    url(../fonts/Lato/Lato-Light-Italic.woff) format("woff");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  src: local("Lato Italic"), local("Lato-Italic"),
    url(../fonts/Lato/Lato-Italic.ttf) format("truetype"),
    url(../fonts/Lato/Lato-Italic.woff2) format("woff2"),
    url(../fonts/Lato/Lato-Italic.woff) format("woff");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 700;
  src: local("Lato Bold Italic"), local("Lato-BoldItalic"),
    url(../fonts/Lato/Lato-Bold-Italic.ttf) format("truetype"),
    url(../fonts/Lato/Lato-Bold-Italic.woff2) format("woff2"),
    url(../fonts/Lato/Lato-Bold-Italic.woff) format("woff");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), local("Lato-Light"),
    url(../fonts/Lato/Lato-Light.ttf) format("truetype"),
    url(../fonts/Lato/Lato-Light.woff2) format("woff2"),
    url(../fonts/Lato/Lato-Light.woff) format("woff");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Lato/Lato-Regular.eot);
  src: local("Lato Regular"), local("Lato-Regular"), local("Lato"),
    url(../fonts/Lato/Lato-Regular.ttf) format("truetype"),
    url(../fonts/Lato/Lato-Regular.svg#Lato) format("svg"),
    url(../fonts/Lato/Lato-Regular.eot?#iefix) format("embedded-opentype"),
    url(../fonts/Lato/Lato-Regular.woff2) format("woff2"),
    url(../fonts/Lato/Lato-Regular.woff) format("woff");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), local("Lato-Bold"),
    url(../fonts/Lato/Lato-Bold.ttf) format("truetype"),
    url(../fonts/Lato/Lato-Bold.woff2) format("woff2"),
    url(../fonts/Lato/Lato-Bold.woff) format("woff");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  src: local("Lato Black"), local("Lato-Black"),
    url(../fonts/Lato/Lato-Black.ttf) format("truetype"),
    url(../fonts/Lato/Lato-Black.woff2) format("woff2"),
    url(../fonts/Lato/Lato-Black.woff) format("woff");
}

/* -------------------------------------------------------------- 
Universal Internet Explorer 6 stylesheet:
http://stuffandnonsense.co.uk/blog/about/universal_internet_explorer_6_css/

Author: Andy Clarke
Web site: http://stuffandnonsense.co.uk
Web site: http://forabeautifulweb.com
Web site: http://transcending.com
Web site: http://hardboiledwebdesign.com
Twitter: http://twitter.com/malarkey

Version date : 13th June 2010
Version: 1.1b Light-on-dark version

License: Creative Commons CC Zero Declaration. No Rights Reserved.

Based on the work of:
Mark Boulton: http://markboulton.co.uk
Eric Meyer: http://meyerweb.com
Cameron Moll: http://cameronmoll.com
Richard Rutter: http://clagnut.com
Khoi Vinh: http://subtraction.com

-------------------------------------------------------------- */

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
code,
del,
dfn,
em,
img,
q,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

/* Body ---------------------------------------------------- */

body {
  max-width: 50em;
  width: 100%;
  margin: 0 auto;
  padding: 2em 0;
  // font : 88% Georgia, Times, serif;
  font: 100% lato, helvetica, sans-serif;
  line-height: 1.4;
  background: #fff;
  color: #000;
}

/* Headings ---------------------------------------------------- */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

h1 {
  margin-bottom: 0.5em;
  font-size: 3em;
  line-height: 1;
}

h2 {
  margin-bottom: 0.75em;
  font-size: 2em;
}

h3 {
  margin-bottom: 1em;
  font-size: 1.5em;
  line-height: 1;
}

h4 {
  margin-bottom: 1.25em;
  font-size: 1.2em;
  line-height: 1.25;
}

h5,
h6 {
  margin-bottom: 1.5em;
  font-weight: bold;
  font-size: 1em;
}

h1 img,
h2 img,
h3 img,
h4 img,
h5 img,
h6 img {
  margin: 0;
}

/* Text elements -------------------------------------------------------------- */

p {
  margin: 0 0 1.5em;
}

a {
  color: #007fc0;
  text-decoration: underline;
}

a:visited {
  color: #007fc0;
}

a:focus,
a:hover {
  color: #007fc0;
  text-decoration: none;
}

a img {
  border: none;
}

blockquote {
  margin: 1.5em 1.5em 1.5em -1.5em;
  padding-left: 1.5em;
  border-left: 1px solid #a8a8a8;
  font: italic 1.2em lato, helvetica, sans-serif;
}

strong {
  font-weight: bold;
}

em,
dfn {
  font-style: italic;
}

dfn {
  font-weight: bold;
}

sup,
sub {
  line-height: 0;
}

abbr,
acronym {
  border-bottom: 1px dotted #a8a8a8;
  cursor: help;
}

address {
  margin: 0 0 1.5em;
  font-style: italic;
}

del {
  color: #666;
}

pre,
code,
tt {
  margin: 1.5em 1.5em 1.5em -1.5em;
  padding-left: 1.5em;
  border-left: 1px dotted #a8a8a8;
  font: 1em "andale mono", "lucida console", monospace;
  line-height: 1.5;
}

pre {
  white-space: pre;
}

/* Lists -------------------------------------------------------------- */

li ul,
li ol {
  list-style-type: circle;
  margin: 0 1.5em 0.75em 1.5em;
}

ul,
ol {
  margin: 0 1.5em 1.5em 0;
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

dl {
  margin-bottom: 1.5em;
  padding-top: 1.5em;
  border-top: 1px solid #a8a8a8;
}

dl dt {
  margin-bottom: 0.75em;
  font-size: 1.2em;
  line-height: 1.25;
}

dd {
  margin-bottom: 1.5em;
  padding-bottom: 1.5em;
  border-bottom: 1px solid #a8a8a8;
}

/* Tables -------------------------------------------------------------- */

table {
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 1.4em;
  width: 100%;
}

table,
td,
th {
  vertical-align: top;
}

th,
thead th {
  font-weight: bold;
}

th,
td,
caption {
  padding: 4px 10px 4px 5px;
  text-align: left;
  font-weight: normal;
}

th,
td {
  border-bottom: 1px solid #a8a8a8;
}

tfoot {
  font-size: 0.9em;
}

caption {
  margin-bottom: 1em;
  font-size: 1.5em;
  line-height: 1;
}

/* Forms -------------------------------------------------------------- */

label {
  font-weight: bold;
}

fieldset {
  margin: 0 0 1.5em 0;
  padding: 1.4em 1.4em 0 1.4em;
  border: 1px solid #a8a8a8;
}

legend {
  font-size: 1.2em;
  font-weight: bold;
}

textarea {
  width: 390px;
  height: 250px;
  padding: 5px;
}




// Custom
// ------
.usr-dropdown .dropdown-item {
  display: block;
}

footer {
  text-align: center;
  margin-top: 40px;
  padding-top: 40px;
}
